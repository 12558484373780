@media screen and (max-width: 768px) {
  body {
    background-color: white;
  }
}

@media screen and (min-width: 768px) {
  body {
    background-color: white;
    padding-top: 80px !important;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Shell', Verdana, sans-serif, 'Shell-Thai' !important;
  font-weight: normal;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  font-smooth: always;
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#root {
  background-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6,
em,
p {
  font-weight: normal;
}

a {
  cursor: pointer;
  display: inline-block;
  font-size: 0.75rem;
  font-family: Roboto, Verdana, sans-serif;
  font-weight: 400;
  line-height: 1.7143;
  letter-spacing: 0.25px;
  text-decoration: underline;
  color: #404040;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

#privacy-policy p {
  margin: 0 0;
}

header#application_title {
  height: 80px;
  text-align: center;
  position: fixed;
  width: 100%;
  top: 0px;
  background-color: #fff;
  z-index: 10;
}

#application_title h3 {
  margin: 0;
  padding: 20px 0 0;
  font-size: 20px;
  font-weight: bold;
  color: red;
  text-transform: uppercase;
}

#application_title h4 {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  color: grey;
  text-transform: uppercase;
}

#application_title h4 a {
  font-size: 14px;
  text-decoration: underline;
  color: grey;
}

#application {
  overflow: hidden;
  background-color: white;
}

.container-fluid {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.hidden {
  display: none;
}

hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px dashed #c5c5c5;
  border-bottom: 1px dashed #f7f7f7;
}

.divider {
  clear: both;
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.divider > span {
  font-size: 11px;
  position: relative;
  display: inline-block;
  color: #7f7f7f;
}

.divider > span:before,
.divider > span:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: #d9d9d9;
}

.divider > span:before {
  right: 100%;
  margin-right: 5px;
}

.divider > span:after {
  left: 100%;
  margin-left: 5px;
}

div.row a.provider {
  margin-bottom: 10px;
}

div.row a.first.provider {
  padding-right: 5px;
}

div.row a.second.provider {
  padding-left: 5px;
}

.react-gen-wizard ul.breadcrumbs {
  display: none;
}

.react-gen-wizard h3.component-title {
  display: none;
}

input::-ms-clear {
  display: none;
}

b,
strong,
.bold {
  font-weight: bold !important;
}

.link {
  text-decoration: underline;
  color: inherit;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #d9d8d8;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #d9d8d8;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #d9d8d8;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d9d8d8;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #d9d8d8;
}

#app {
  height: 100%;
}

.main-loader {
  position: absolute;
  height: auto;
}

.loader {
  position: relative;
  height: 85vh;
}

.overview .loader {
  height: 100vh;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.notification-visible,
.popup-visible {
  overflow: hidden !important;
}

.icon {
  cursor: pointer;
}

/* Fix for styling disabled state as the 0.x version of MUI does not allow it */

.text-form-field .disabled p:nth-child(1) {
  font-size: 12px !important;
}

.text-form-field .disabled p:nth-child(2) {
  font-size: 18px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.grecaptcha-badge {
  visibility: hidden;
}

.loadedContent {
  height: 100%;
}

/* width */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Hide reveal password button */

input[password]::-ms-reveal {
  display: none;
}

.password {
  -webkit-text-security: disc;
  -moz-text-security: disc;
}

button {
  border: none;
  background-color: transparent;
}
